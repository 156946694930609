<template>
  <div style="float: right;">
    <b-button @click="showModal = true">
      Send Invoices
    </b-button>

    <b-modal
      v-model="showModal"
      :width="500"
    >
      <div class="card p-4">
        <div class="is-size-7 mb-2">
          Enter the first invoice number to be sent (we will send one by one, starting with that number)
        </div>
        <div class="columns">
          <div class="column">
            <b-input
              v-model="startAt"
              type="number"
            />
          </div>
          <div class="column">
            <b-button
              :disabled="!!isLoading"
              :loading="!!isLoading"
              class="is-primary"
              @click="sendAllInvoices"
            >
              Send
            </b-button>
          </div>
        </div>
        <div class="is-size-7 mb-2">
          {{ sentIds.length }} sent
          /
          {{ failedIds.length }} failed
          /
          {{ ignoredIds.length }} ignored
          /
          {{ filteredItems.length }} total
        </div>
        <div
          v-if="isLoading"
          v-text="'⏳ ' + isLoading"
        />
        <div
          v-for="id in failedIds"
          :key="id"
          v-text="'❌ ' + id"
        />
        <div
          v-for="id in sentIds"
          :key="id"
          v-text="'✅ ' + id"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import gql from 'graphql-tag'

const IGNORED_INVOICE_IDS = [
  '20241205001',
  '20241205005',
  '20241205004',
  '20241205006',
  '20241205007',
  '20241206001',
  '20241206002',
  '20241206007',
  '20241206009',
  '20241207001',
]

export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      showModal: false,
      startAt: '',
      isLoading: null,
      sentIds: [],
      failedIds: [],
      ignoredIds: [],
    }
  },
  computed: {
    filteredItems () {
      const min = Number(this.startAt)
      return this.items
        .filter(item => item.id >= min)
        .sort((a, b) => a.id - b.id)
    },
  },
  methods: {
    async sendAllInvoices () {
      if (this.startAt.length !== 11) {
        this.$buefy.toast.open({
          message: 'Invalid invoice number! Must be 11 digits long.',
          type: 'is-danger',
        })
        return
      }
      this.sentIds = []
      this.failedIds = []
      this.ignoredIds = []
      for (const item of this.filteredItems) {
        // ignore from the hardcoded list
        if (IGNORED_INVOICE_IDS.includes(item.id)) {
          this.ignoredIds.push(item.id)
          continue
        }
        // ignore invoices with an ID >= 20241001000
        if (item.id >= '20241001000') {
          this.ignoredIds.push(item.id)
          continue
        }
        this.isLoading = item.id
        await this.sendInvoice(item.id)
      }
      this.isLoading = null
    },
    async sendInvoice (id) {
      try {
        const { data } = await this.$apollo.query({
          query: gql`query ($id: ID!) {
            invoiceSendSleek (id: $id)
          }`,
          variables: {
            id,
          },
          fetchPolicy: 'no-cache',
        })
        if (data.invoiceSendSleek)
          this.sentIds.unshift(id)
        else
          throw new Error(`Invoice ${id} could not be sent`)
      } catch (e) {
        this.failedIds.unshift(id)
        this.$buefy.toast.open({
          message: e.message,
          type: 'is-danger',
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
