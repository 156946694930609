import { render, staticRenderFns } from "./SendInvoices.vue?vue&type=template&id=1d2e7791&scoped=true&"
import script from "./SendInvoices.vue?vue&type=script&lang=js&"
export * from "./SendInvoices.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d2e7791",
  null
  
)

export default component.exports